import ab_bg from "../../assets/images/others/ab-bg.png";
import { Link } from "react-router-dom";

import { scrollToTop } from "../../utils/scrollUtils";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";

const data = [
  {
    icon: "fa-regular fa-circle-check",
    title: "Transparencia",
    link: "https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=10834",
    target: "_blank",
  },
  {
    icon: "fa-solid fa-magnifying-glass",
    title: "TUPA",
    link: "/municipalidad/instrumentos-de-gestion/tupa",
  },
  {
    icon: "far fa-computer-speaker",
    title: "Mesa de Partes Virtual",
    link: "/servicios/mesa-de-partes-virtual",
  },
  {
    icon: "fas fa-book",
    title: "Libro de Reclamaciones",
    link: "/servicios/libro-de-reclamaciones",
  },
  {
    icon: "far fa-file-alt",
    title: "Instrumentos de Gestión",
    link: "/municipalidad/instrumentos-de-gestion",
  },
  {
    icon: "fa-solid fa-user-tie",
    title: "Nuestra Municipalidad",
    link: "/municipalidad",
  },
];

const AccessItem = ({ icon, title, link, target }) => (
  <Link
    to={link}
    onClick={() => {
      if (!target) {
        scrollToTop();
      }
    }}
    className="col "
    target={target}
  >
    <div className="tp-feature-item mb-10 mt-10 text-center">
      <div className="tp-feature-icon">
        <i className={icon} />
      </div>
      <div className="tp-feature-content">
        <h4
          className="tp-feature-title-sm"
          style={{
            textTransform: "uppercase",
          }}
        >
          {title}
        </h4>
      </div>
    </div>
  </Link>
);

function QuickAccess() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="arrow-prev" onClick={onClick}>
        <i className="fa-solid fa-chevron-left" />
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="arrow-next" onClick={onClick}>
        <i className="fa-solid fa-chevron-right" />
      </div>
    );
  }

  // eliminar esta clase al cargar "slick-arrow slick-next"
  useEffect(() => {
    const nextArrow = document.querySelector(".slick-arrow.slick-next");
    if (nextArrow) {
      nextArrow.classList.remove("slick-next");
    }

    const prevArrow = document.querySelector(".slick-arrow.slick-prev");
    if (prevArrow) {
      prevArrow.classList.remove("slick-prev");
    }
  }, []);

  return (
    <>
      <div className="tp-feature-area pt-60 pb-60 p-relative z-index grey-bg-2">
        <h2 className="d-flex justify-content-center pb-30 text-center">
          Accesos rápidos
        </h2>
        <div className="tp-feature-shape-2">
          <img src={ab_bg} alt="backgroung" />
        </div>
        <Slider {...settings} draggable={false} className="container">
          {data.map((item, index) => (
            <AccessItem key={index} {...item} />
          ))}
        </Slider>
      </div>
    </>
  );
}

export default QuickAccess;
