import React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import {
  mofList,
  muniFolders,
  ordenanzasList,
  organigramaList,
  p_participativo_2025_List,
  presupuesto_2025_List,
  resolucionesList,
  rofList,
  transferencia_de_partidasList,
  tupaList,
} from "./utils/getDocuments";

// Layout
import MainLayout from "./layouts/MainLayout";
// Home
import Home from "./pages/home/Home";
// Municipality
import Municipality from "./pages/municipality/Municipality";
import About from "./pages/municipality/About";
import MainOffice from "./pages/municipality/MainOffice";
import DistrictCreation from "./pages/municipality/DistrictCreation";
import MissionAndVision from "./pages/municipality/MissionAndVision";
import InstrumentsList from "./pages/municipality/management_instruments/InstrumentsList";
import ManagementInstruments from "./pages/municipality/management_instruments/ManagementInstruments";
// Services
import Services from "./pages/services/Services";
import PartsTable from "./pages/services/PartsTable";
import ServiceList from "./pages/services/ServiceList";
import ComplaintsBook from "./pages/services/ComplaintsBook";
// District
import District from "./pages/district/District";
// News
import News from "./pages/news/News";
// Contact
import Contact from "./pages/contact/Contact";
// 404
import NotFound from "./pages/404/NotFound";
// Privacy Policy
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
// Documents
import Folders from "./components/muni/Folders";
import Documents from "./components/muni/Documents";

function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  if (isMaintenanceMode) {
    return (
      <div className="App">
        <MainLayout>
          <Routes>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MainLayout>
      </div>
    );
  }

  return (
    <div className="App">
      <MainLayout>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            success: {
              duration: 3000,
            },
            error: {
              duration: 3000,
            },
          }}
        />
        <Routes>
          <Route index element={<Home />} />
          <Route path="municipalidad" element={<Municipality />}>
            <Route index element={<About />} />
            <Route path="sede-central" element={<MainOffice />} />
            <Route
              path="ley-de-creacion-distrital"
              element={<DistrictCreation />}
            />
            <Route path="mision-y-vision" element={<MissionAndVision />} />
            <Route
              path="instrumentos-de-gestion"
              element={<ManagementInstruments />}
            >
              <Route
                index
                element={
                  <InstrumentsList
                    Data={() => <Folders items={muniFolders} />}
                  />
                }
              />
              <Route
                path="mof"
                element={
                  <InstrumentsList
                    folder="MOF"
                    Data={() => <Documents title="MOF" items={mofList} />}
                  />
                }
              />
              <Route
                path="ordenanzas"
                element={
                  <InstrumentsList
                    folder="Ordenanzas"
                    Data={() => (
                      <Documents title="Ordenanzas" items={ordenanzasList} />
                    )}
                  />
                }
              />
              <Route
                path="organigrama"
                element={
                  <InstrumentsList
                    folder="Organigrama"
                    Data={() => (
                      <Documents title="Organigrama" items={organigramaList} />
                    )}
                  />
                }
              />
              <Route
                path="presupuesto-participativo-2025"
                element={
                  <InstrumentsList
                    folder="Presupuesto Participativo 2025"
                    Data={() => (
                      <Documents
                        title="Presupuesto Participativo 2025"
                        items={p_participativo_2025_List}
                      />
                    )}
                  />
                }
              />
              <Route
                path="presupuesto-2025"
                element={
                  <InstrumentsList
                    folder="Presupuesto 2025"
                    Data={() => (
                      <Documents
                        title="Presupuesto 2025"
                        items={presupuesto_2025_List}
                      />
                    )}
                  />
                }
              />
              <Route
                path="resoluciones"
                element={
                  <InstrumentsList
                    folder="Resoluciones"
                    Data={() => (
                      <Documents
                        title="Resoluciones"
                        items={resolucionesList}
                      />
                    )}
                  />
                }
              />
              <Route
                path="rof"
                element={
                  <InstrumentsList
                    folder="ROF"
                    Data={() => <Documents title="ROF" items={rofList} />}
                  />
                }
              />
              <Route
                path="transferencia-de-partidas"
                element={
                  <InstrumentsList
                    folder="Transferencia de Partidas"
                    Data={() => (
                      <Documents
                        title="Transferencia de Partidas"
                        items={transferencia_de_partidasList}
                      />
                    )}
                  />
                }
              />
              <Route
                path="tupa"
                element={
                  <InstrumentsList
                    folder="TUPA"
                    Data={() => <Documents title="TUPA" items={tupaList} />}
                  />
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="servicios" element={<Services />}>
            <Route index element={<ServiceList />} />
            <Route path="mesa-de-partes-virtual" element={<PartsTable />} />
            <Route path="libro-de-reclamaciones" element={<ComplaintsBook />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="distrito" element={<District />} />
          <Route path="noticias" element={<News />} />
          <Route path="contacto" element={<Contact />} />
          <Route path="politica-de-privacidad" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
    </div>
  );
}

export default App;
