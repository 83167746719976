export const menuItems = [
  { path: "/", text: "Inicio" },
  {
    path: "/municipalidad",
    text: "Municipalidad",
    submenu: [
      { path: "/sede-central", text: "Sede central" },
      { path: "/ley-de-creacion-distrital", text: "Ley de creación distrital" },
      { path: "/mision-y-vision", text: "Misión y visión" },
      { path: "/instrumentos-de-gestion", text: "Instrumentos de gestión" },
      {
        path: "https://mpesije.jne.gob.pe/docs/8b3a4854-3e48-44d4-a8ed-9c97b36e7647.pdf",
        text: "Plan de gobierno municipal",
        target: "_blank",
      },
      // { path: "/organigrama-municipal", text: "Organigrama municipal" },
      // {
      //   path: "/directorio-de-funcionarios",
      //   text: "Directorio de funcionarios",
      // },
    ],
  },
  {
    path: "/servicios",
    text: "Servicios",
    submenu: [
      {
        path: "https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=10834",
        text: "Transparencia",
        target: "_blank",
      },
      {
        path: "/mesa-de-partes-virtual",
        text: "Mesa de partes virtual",
      },
      { path: "/libro-de-reclamaciones", text: "Libro de reclamaciones" },
    ],
  },
  { path: "/distrito", text: "Distrito" },
  { path: "/noticias", text: "Noticias" },
  { path: "/contacto", text: "Contacto" },
];
