// Files

const mof = ["MOF.pdf"];

const ordenanzas = ["ORDENANZA MUNICIPAL N° 003-2024-MDLLP.pdf"];

const organigrama = ["Organigrama.pdf"];

const p_participativo_2025 = [
    "Decreto de Alcaldía.pdf",
    "Equipo Técnico.pdf",
    "Ordenanza Municipal.pdf",
    "Presupuesto Participativo 2025.pdf",
    "Proceso de Presupuesto Participativo 2025.pdf",
    "Relación de Agentes.pdf",
    "Solicitud de Registro de Agentes.pdf",
];

const presupuesto_2025 = [
    "PLAN DE DESARROLLO CONCERTADO.pdf",
    "SUBGERENCIA DE DESARROLLO SOCIAL.pdf",
    "SUBGERENCIA DE INFRAESTRCTURA.pdf",
    "TECHOS PRESUPUESTALES AÑO FISCAL 2025.pdf",
    "UNIDAD DE PLANIFICACION Y PRESUPUESTO.pdf",
];

const resoluciones = ["RESOLUCIÓN DE ALCALDÍA N° 25-2024-MDLLP.pdf"];

const rof = ["ROF.pdf", "ROF 2024.pdf"];

const transferencia_de_partidas = [
    "TRANSFERENCIA DE PARTIDAS A FAVOR DE DIVERSOS GOBIERNOS LOCALES.pdf",
    "D.S N° 061-2024-EF 24.04.2024.pdf",
];

const tupa = ["TUPA.pdf"];

// Map function

function createDocumentObject(folder) {
    return function (fileName) {
        const pdf = require(`../assets/documents/${folder}/${fileName}`);
        return {
            title: fileName,
            path: pdf,
            target: "_blank",
        };
    };
}

// Documents

export const mofList = mof.map(createDocumentObject("mof"));

export const ordenanzasList = ordenanzas.map(createDocumentObject("ordenanzas"));

export const organigramaList = organigrama.map(createDocumentObject("organigrama"));

export const p_participativo_2025_List = p_participativo_2025.map(createDocumentObject("presupuesto-participativo-2025"));

export const presupuesto_2025_List = presupuesto_2025.map(createDocumentObject("presupuesto-2025"));

export const resolucionesList = resoluciones.map(createDocumentObject("resoluciones"));

export const rofList = rof.map(createDocumentObject("rof"));

export const transferencia_de_partidasList = transferencia_de_partidas.map(createDocumentObject("transferencia-de-partidas"));

export const tupaList = tupa.map(createDocumentObject("tupa"));

// Folders

export const muniFolders = [
    {
        title: "MOF",
        path: "/municipalidad/instrumentos-de-gestion/mof",
    },
    {
        title: "Ordenanzas",
        path: "/municipalidad/instrumentos-de-gestion/ordenanzas",
    },
    {
        title: "Organigrama",
        path: "/municipalidad/instrumentos-de-gestion/organigrama",
    },
    {
        title: "Presupuesto Participativo 2025",
        path: "/municipalidad/instrumentos-de-gestion/presupuesto-participativo-2025",
    },
    {
        title: "Presupuesto 2025",
        path: "/municipalidad/instrumentos-de-gestion/presupuesto-2025",
    },
    {
        title: "Resoluciones",
        path: "/municipalidad/instrumentos-de-gestion/resoluciones",
    },
    {
        title: "ROF",
        path: "/municipalidad/instrumentos-de-gestion/rof",
    },
    {
        title: "Transferencia de Partidas",
        path: "/municipalidad/instrumentos-de-gestion/transferencia-de-partidas",
    },
    {
        title: "TUPA",
        path: "/municipalidad/instrumentos-de-gestion/tupa",
    },
];
